import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/deleteAccount/Content";

const About = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="Delete account from Kesto">
        </Hero>
        <Content />
      </PageWrapper>
    </>
  );
};
export default About;
