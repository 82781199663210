import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import s1 from "../../assets/image/png/s1.png";
import s2 from "../../assets/image/png/s2.png";
import w1 from "../../assets/image/png/w1.png";
import w2 from "../../assets/image/png/w2.png";
// import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
  border-radius: 15px;
`;


const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="">
          <Col lg="6" className=" pb-3">
            <div className="">
              <Title variant="heroCustom">Delete account from Kesto Mobile application</Title>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="6" className="pl-lg-5 pt-4">
            <Text variant="card" className="text-left">Step 1: Goto Profile -> Account setting </Text>
            <Box borderRadius={10} className="position-relative overflow-hidden">
              <img src={s1} alt="" className="img-fluid" style={{'border': '1px solid black', 'border-radius': '15px'}} />
            </Box>
          </Col>
          <Col lg="6" className="pl-lg-5 pt-4">
            <Text variant="card" className="text-left">Step 2: Click on Delete account button</Text>
            <Box borderRadius={10} className="position-relative overflow-hidden">
              <img src={s2} alt="" className="img-fluid" style={{'border': '1px solid black', 'border-radius': '15px'}}/>
            </Box>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col lg="6" className="pb-3">
            <div className="">
              <Title variant="heroCustom">Delete account from Kesto website</Title>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="6" className="pl-lg-5 pt-4">
            <Text variant="card" className="text-left">Step 1: Goto Profile -> Account setting </Text>
            <Box borderRadius={10} className="position-relative overflow-hidden">
              <img src={w1} alt="" className="img-fluid" style={{'border': '1px solid black', 'border-radius': '15px'}} />
            </Box>
          </Col>
          <Col lg="6" className="pl-lg-5 pt-4">
            <Text variant="card" className="text-left">Step 2: Click on Delete account button</Text>
            <Box borderRadius={10} className="position-relative overflow-hidden">
              <img src={w2} alt="" className="img-fluid" style={{'border': '1px solid black', 'border-radius': '15px'}} />
            </Box>
          </Col>
        </Row>

      </Container>
    </Section>
  </>
);

export default Content;
